<template>
  <div>
    <div class="api-subscription-title has-text-grey-dark has-text-centered">Credit Used Today</div>
    <Spinner v-show="showInProgressSection" message="Loading your credit details. Please wait..."/>
    <DoodleMessage class="has-text-grey-dark" :message="doodleForm.errorMessage" message-class="has-text-danger" v-show="doodleForm.errorMessage"/>
    <div class="has-text-centered" v-if="this.doodleForm.completed">
      <div class="api-subscription-detail is-size-6">
        <div class="has-text-weight-bold has-text-grey-dark">Used until now - <span class="has-text-weight-bold has-text-danger">{{creditUsed}}</span>
        </div>
        <div class="has-text-centered has-text-weight-bold">Note: Credit resets at 12:00 AM UTC every day</div>
      </div>
    </div>
  </div>
</template>

<script>
import formMixin from '../../assets/javascript/form-mixin'
import Spinner from '../Utils/Spinner'
import DoodleMessage from '../Utils/DoodleMessage'

export default {
  name: 'apiCreditUsed',
  components: { DoodleMessage, Spinner },
  mixins: [formMixin],
  data: function () {
    return {
      creditUsed: null
    }
  },
  mounted () {
    this.executeAPIWitoutValiation({
      url: '/api/compiler-api/creditUsedToday',
      successAction: (data) => {
        this.creditUsed = (data.used) ? data.used : 0
      },
      markCompleted: true,
      form: this.doodleForm,
      jdaCategory: window.jda.CATEGORY.API,
      jdaEvent: 'credit-used',
      jdaLabel: ''
    })
  }
}
</script>

<style scoped lang="scss">
  .api-subscription-title {
    text-decoration: underline;
    font-weight: bold;
    font-size: 1em;
  }

  .api-subscription-detail {
    margin-top: 1em;
    margin-bottom: 1em;
    font-size: 0.75em;
  }

  .api-subscription-sub-detail {
    font-weight: bold;
    font-size: 0.8em;
  }
</style>
